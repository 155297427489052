@use './reset.scss';

* {
  scroll-behavior: smooth;
  outline-color: #00d0ff;
}

@media (prefers-reduced-motion) {
  * {
    scroll-behavior: auto;
  }
}

:target {
  scroll-margin-top: 80px;
}

@media (max-width: 540px) {
  html {
    font-size: 87.5%;
  }
}

body {
  font-family: 'Jost', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #2b2b37;
  overflow-x: hidden;
  font-feature-settings: 'ss01';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
